if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('./service-worker.js').then(registration => {
      console.log('SW registered: ', registration);
    }).catch(registrationError => {
      console.log('SW registration failed: ', registrationError);
    });
  });
}

var from = 0
var limit = 2000
var last_mousemove = new Date().getTime()
var numbers = []
var luuucky = null
var app_events = null
var do_hide = true
var lottery_type = "numeral"
var books = null
var book_limit = null
var bg_colors = ['#00D069',' #0ac2d2','#1DB1D8','#fd6a62','#f68dbb',' #fdc162','#60d7a9','#333','#AC92EC','#7bb7fa'];
var current_color = 0
var timeout = null

document.addEventListener('DOMContentLoaded', (event) => {
  luuucky = document.getElementById("app-luuucky")

  const startBtn = document.getElementById("start")
  startBtn.addEventListener("click", e => start())

  const logBtn = document.getElementById("log-trigger")
  logBtn.addEventListener("click", e => luuucky.classList.toggle("loggy"))
  
  const numeralTrigger = document.getElementById("numeral_trigger")
  numeralTrigger.addEventListener("click", e => {
    luuucky.classList.add("numeral")
    luuucky.classList.remove("books")
    lottery_type = "numeral"
  })

  const booksTrigger = document.getElementById("books_trigger")
  booksTrigger.addEventListener("click", e => {
    luuucky.classList.add("books")
    luuucky.classList.remove("numeral")
    lottery_type = "books"
  })

  document.addEventListener("mousemove", e => {
    const elements = document.querySelectorAll(".auto-hide")
    elements.forEach(el => {
      el.classList.add("fadeIn")
      el.classList.remove("fadeOut")
    });
    clearTimeout(timeout)
    timeout = setTimeout(() => hideMenu(), 2000)
  })

  document.addEventListener('keyup', e => {
    if(e.code == 'Space') { e.preventDefault(); next(); return false; }
	  if(e.code == 'Enter') { e.preventDefault(); next(); return false; }	 
  });

});

start = function() {
  if(lottery_type == "books"){
    start_book()
  } else {
    start_numeral()
  }
}

cancel = function() {
  hide(document.getElementById("finished"))
  hide(document.getElementById("screen"))
  luuucky.classList.remove("active")
  luuucky.style.height = ""
  document.querySelector("#log .wrap").innerHTML = ""
  document.querySelector(".auto-hide").classList.add("fadeOut")
}

start_numeral = function() {
  from = parseInt(document.getElementById("from").value)
  limit = parseInt(document.getElementById("limit").value)

  if(from >= limit){
    document.querySelector(".details").prepend("<p class='info wrong-range'>" + __('Woops! Invalid range...') + "</p>")	
  } else {
   start_luuucky()
  }
}

start_book = function(){
  books = parseInt(document.getElementById("books").value)
  book_limit = parseInt(document.getElementById("book_limit").value)

	if(books == 0 || book_limit == 0){
    document.querySelector(".details").prepend("<p class='info wrong-range'>" + __('Woops! Invalid range...')  + "</p>")	
	} else {
		start_luuucky()
	}
}

start_luuucky = function() {
  const el = document.querySelector(".wrong-range")
  if (el != null) el.remove()

  hide(document.getElementById("finished"))
  document.getElementById("screen").style.display = "block"
  document.getElementById("screen").classList.add("fadeIn")
  luuucky.classList.add("active")
  document.querySelector("#log .wrap").innerHTML = ""
  numbers = []

  if(is_touch_device()){
    const elements = document.querySelectorAll(".auto-hide")
    elements.forEach(element => {show(element)});
	} else {
		setTimeout("hideMenu()",3000)

    window.addEventListener("mousemove", e => {
      if(appIsActive()) {

      }
    })
	}

  next()
}

next_numeral = function() {
  if(numbers.length > limit - from){
    hide(document.getElementById("screen"))
    show(document.getElementById("finished"))
		return false
	} 
  number = drawNumber()
  while (numbers.includes(number)) {
    number = drawNumber()
  }
	numbers.push(number)

  document.querySelector("#log .wrap").innerHTML +=  number + " <span>-</span> "
  document.getElementById("the_number").innerHTML = number
}

drawNumber = () => Math.floor(Math.random() * (limit - from + 1) + from);

next_book = function(){	
	the_book = Math.floor(Math.random()*books) + 1
	the_number = Math.floor(Math.random()*book_limit) + 1
	document.querySelector("#log .wrap").innerHTML += "B"+the_book + " T" + the_number +" <span>-</span> "
	document.getElementById("the_number").innerHTML = "<span class='book-nr' title=" + __('BOOK') + ">" + the_book + "</span> <span class='ticket-nr' title=" + __('TICKET') + ">" + the_number + "</span>"

}

is_touch_device = function() {
  return (('ontouchstart' in window) ||
    (navigator.maxTouchPoints > 0) ||
    (navigator.msMaxTouchPoints > 0));
}

hideMenu = function() {
  if (appIsActive() && do_hide) {
    const elements = document.querySelectorAll(".auto-hide")
    elements.forEach(el => {
      el.classList.remove("fadeIn")
      el.classList.add("fadeOut")
    });
  }
}

toggleFullScreen = function() {
  if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
  } else {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  }
}

change_colors = function() {
  if(bg_colors[++current_color] == undefined){
    current_color = 0
  }

  luuucky.style.backgroundColor = bg_colors[current_color]
  const elements = document.querySelectorAll("input")
  elements.forEach(element => {
    element.style.color = bg_colors[current_color]
  });
}

next = () => lottery_type == "books" ? next_book() : next_numeral()
appIsActive = () =>  luuucky.classList.contains("active");
show = (el) => el.style.display = "block"
hide = (el) => el.style.display = "none"